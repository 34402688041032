import { EntityBrandFormConfig, ImageScaleType, MarketingTemplate, MarketingTemplateItem, MarketingTemplateSection, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { A4_WIDTH, MARGIN_ITEM_LEFT, MARGIN_LEFT, MARGIN_RIGHT, mmToPoints } from '../measurements';
import { canonicalisers } from '../../formatting';
import { checkbox, checkboxChecked } from '../../../assets/checkbox';
import { bookmarkAnchor, insertIf, itemSubsection, noborder } from '../index';
import { parseSync, stringify } from 'svgson';
import { MarketingTemplateItemOld, MarketingTemplateOld } from '@property-folders/contract';
import { rayWhiteMarketingTemplateHeader } from '@property-folders/common/assets/raywhite';
import { groupBy, flatMap } from 'lodash';
import { subsectionTitles } from '../doc-constants/sales-agency-agreement';
import { marketingSectionTotal } from '../../marketingTemplates';
import { Predicate } from '../../../predicate';

export function marketingTemplateSection(marketingTemplate: MarketingTemplate, isVariation = false, noMargins?: boolean) {
  const sectionItems = [];
  const textGrey = '#808080';
  const width = A4_WIDTH - (noMargins ? 0 : MARGIN_LEFT + MARGIN_RIGHT + MARGIN_ITEM_LEFT);
  const { headerImageScale: scale } = marketingTemplate;

  if (!isVariation) {
    sectionItems.push({
      image: 'marketingHeaderImage',
      width: scale === ImageScaleType.stretch ? width : undefined,
      height: scale === ImageScaleType.stretch ? width / 4.3515 : undefined,
      fit: scale === ImageScaleType.fit ? [ width,width / 4.3515 ] : undefined,
      margin: [0, 0, 0, 0]
    });

    marketingTemplate?.headerText && sectionItems.push(
      {
        table: {
          headerRows: 1,
          body: [[{
            stack: [
              {
                text: marketingTemplate?.headerText,
                fontSize: 22,
                color: marketingTemplate.textColour,
                margin: [0, 0, 0, 3]
              }
            ],
            fillColor: marketingTemplate.backgroundColour,
            fillOpacity: 0.85
          }]]
        },
        layout: {
          defaultBorder: false
        },
        relativePosition: { x: 15, y: -85 }
      },
    );
  }

  const sections = marketingTemplate.sections;
  let idx = 0;
  const variationMargin = isVariation ? 38 : 0;

  function changeSvgColor(data: string, selector: string, color: string) {
    const svg = parseSync(data);
    for (const child of svg.children.filter(c => c.name === 'path')) {
      child.attributes.style = `fill: ${color}`;
    }
    return stringify(svg);
  }

  const marketingSectionSummation = (acc: number, cv: MarketingTemplateSection) => acc + marketingSectionTotal(cv);

  sectionItems.push(sections.map((section) => {
    const sectionTotalNumeric = marketingSectionTotal(section);
    const sectionTotal = canonicalisers.audWithNegative(sectionTotalNumeric||0).display;

    const isPackage = section.type === MarketingTemplateSectionType.package;
    return [{
      unbreakable: true,
      stack: [
        {
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(1.1),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: A4_WIDTH - (noMargins ? 0 : MARGIN_LEFT + MARGIN_RIGHT + variationMargin + MARGIN_ITEM_LEFT), y: 0 }],
            lineColor: section.lineColour || marketingTemplate.sectionLineColour
          }],
          margin: [0, idx++ ? 6 : 0, 0, 0]
        },
        bookmarkAnchor(`subsection-sub-${section.id}`),
        {
          table: {
            widths: [10, '*', 80],
            heights: (row: number) => !row ? 22 : 5,
            color: textGrey,
            body: [
              [{
                colSpan: 3,
                columns: [
                  isPackage
                    ? {
                      width: 15,
                      svg: changeSvgColor(section.enabled ? checkboxChecked : checkbox, 'path', section.textColour || marketingTemplate.sectionTextColour),
                      fit: [15, 15],
                      margin: [-2, 6.5, 0, 0],
                      border: [0, 0, 0, 0]
                    } : null,
                  { width: '*', text: section.name||' ', fontSize: 15, ...(isPackage && { margin: [0,5.5,0,0] }) },
                  isPackage
                    ? { width: 'auto', alignment: 'right', text: canonicalisers.audWithNegative(section.price)?.display||' ', fontSize: 15, margin: [0, 6, 0, 0] }
                    : null
                ].filter(Predicate.isNotNullish), // Allowing us to have null for cols we don't want
                border: [0, 0, 0, 0],
                verticalAlign: 'middle',
                color: section.textColour || marketingTemplate.sectionTextColour,
                fillColor: section.backgroundColour || marketingTemplate.sectionBackgroundColour
              }, {}, {}],
              ...(section.items?.filter(i => i.description)?.map(i => ([
                {
                  ...section.type === MarketingTemplateSectionType.individual
                    ? {
                      svg: i.enabled ? checkboxChecked : checkbox,
                      fit: [15, 15],
                      margin: [-2, 0, 0, -4],
                      border: [0, 0, 0, 0]
                    }
                    : {
                      text: i.description || '',
                      border: [0, 0, 0, 1],
                      margin: [2, 1, 0, -1],
                      color: textGrey,
                      colSpan: 2
                    }
                },
                {
                  text: i.description || '',
                  border: [0, 0, 0, 1],
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                },
                {
                  text: section.type === MarketingTemplateSectionType.individual ? canonicalisers.audWithNegative(i.price).display || '' : '',
                  border: [0, 0, 0, 1],
                  alignment: 'right',
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                }
              ])) || []),
              ...insertIf(section.type === MarketingTemplateSectionType.package || section.items?.filter(i => i.description)?.length,
                [{ text: '', border: noborder }, { text: '', border: noborder }, {
                  text: sectionTotal || '',
                  border: noborder,
                  fontSize: 12,
                  alignment: 'right',
                  margin: [0, 3, 0, 0],
                  color: textGrey
                }]),
              ...insertIf(idx === Object.keys(sections).length, //Grand Total - only show on last section
                [
                  { text: '', border: noborder },
                  { text: 'Total', border: noborder, fontSize: 12, bold: true, margin: [0, 5, 0, 0] },
                  {
                    text: canonicalisers.audWithNegative(sections?.reduce(marketingSectionSummation, 0).toString()).display,
                    fontSize: 12,
                    bold: true,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                    border: [0, 0, 0, 1],
                    borderColor: ['', '', '', '#000']
                  }]
              )
            ]
          },
          layout: {
            fillColor: (row: number) => !row ? '#D9D9D9' : '#FFFFFF',
            hLineWidth: () => 0.5,
            hLineColor: () => '#D9D9D9'
          }
        },
        bookmarkAnchor(`subsection-sub-${section.id}_!END`),
        {
          stack: [],
          margin: [0,0,0,5]
        }
      ]
    }
    ];}));

  return itemSubsection({
    subsectionTitle: subsectionTitles.marketingCosts,
    subsectionContent: sectionItems,
    isVariation: false,
    unbreakable: false
  });
}

/**
 * @deprecated - this is the old marketing template renderer, still needed for legacy properties
 */
export function rayWhiteMarketingTemplateSection(marketingTemplate: MarketingTemplateOld, marketingFees?: MarketingTemplateItemOld[], brand?: EntityBrandFormConfig, isVariation = false) {
  const sectionItems = [];
  const textGrey = '#808080';

  if (!isVariation) {
    sectionItems.push({
      image: rayWhiteMarketingTemplateHeader,
      width: A4_WIDTH - MARGIN_LEFT - MARGIN_RIGHT - MARGIN_ITEM_LEFT,
      margin: [0, 2, 0, 0]
    });

    sectionItems.push(
      {
        table: {
          headerRows: 1,
          body: [[{
            stack: [
              {
                text: (marketingTemplate?.title || 'Your Marketing Package'),
                fontSize: 22,
                color: textGrey,
                margin: [0, 0, 0, 4]
              },
              {
                text: marketingTemplate?.subTitle || '',
                fontSize: 15,
                color: textGrey
              }
            ],
            fillColor: '#D9D9D9',
            fillOpacity: 0.85
          }]]
        },
        layout: {
          defaultBorder: false
        },
        relativePosition: { x: 15, y: -85 }
      },
    );
  }

  const sections = groupBy(marketingFees, f => f.section);
  let idx = 0;
  const variationMargin = isVariation ? 38 : 0;
  function marketingItemSummation (acc: number, cv: MarketingTemplateItem) {
    const canonObject = canonicalisers.audWithNegative(cv?.itemCost);
    const newValue = cv?.enable ? (typeof canonObject.canonical === 'number' ? canonObject.canonical : 0) : 0;
    return acc + newValue;
  }
  sectionItems.push(...flatMap(sections, (item, section) => {
    const sectionTotalNumeric = item.reduce(marketingItemSummation, 0);
    const sectionTotal = canonicalisers.audWithNegative(sectionTotalNumeric.toString()).display;
    return [{
      unbreakable: true,
      stack: [
        {
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(1.1),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: A4_WIDTH - MARGIN_LEFT - MARGIN_RIGHT - variationMargin - MARGIN_ITEM_LEFT, y: 0 }],
            lineColor: brand?.lineColour
          }],
          margin: [0, idx++ ? 6 : 0, 0, 0]
        },
        bookmarkAnchor(`subsection-sub-${section.replace(' ', '-')}`),
        {
          table: {
            widths: [10, '*', 80],
            heights: (row: number) => !row ? 22 : 5,
            color: textGrey,
            body: [
              [{
                colSpan: 3,
                columns: [{ text: section, fontSize: 15 }, idx === 1 ? { alignment: 'right', text: marketingTemplate?.agentName || '', fontSize: 12, margin: [0, 3, 0, 0] } : {}],
                border: [0, 0, 0, 0],
                verticalAlign: 'middle',
                color: textGrey
              }, {}, {}],
              ...(item?.filter(i => i.itemDesc)?.map(i => ([
                {
                  svg: i.enable ? checkboxChecked : checkbox,
                  fit: [15, 15],
                  border: [0, 0, 0, 0],
                  margin: [-2, 0, 0, -4]
                },
                {
                  text: i.itemDesc || '',
                  border: [0, 0, 0, 1],
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                },
                {
                  text: i.itemCost || '',
                  border: [0, 0, 0, 1],
                  alignment: 'right',
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                }
              ])) || []),
              [{ text: '', border: noborder }, { text: '', border: noborder }, {
                text: sectionTotal,
                border: noborder,
                fontSize: 12,
                alignment: 'right',
                margin: [0, 3, 0, 0],
                color: textGrey
              }],
              ...insertIf(idx === Object.keys(sections).length, //Grand Total - only show on last section
                [
                  { text: '', border: noborder },
                  { text: 'Total', border: noborder, fontSize: 12, bold: true, margin: [0, 5, 0, 0] },
                  {
                    text: canonicalisers.audWithNegative(marketingFees?.reduce(marketingItemSummation, 0).toString()).display,
                    fontSize: 12,
                    bold: true,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                    border: [0, 0, 0, 1],
                    borderColor: ['', '', '', '#000']
                  }]
              )
            ]
          },
          layout: {
            fillColor: (row: number) => !row ? '#D9D9D9' : '#FFFFFF',
            hLineWidth: () => 0.5,
            hLineColor: () => '#D9D9D9'
          }
        },
        bookmarkAnchor(`subsection-sub-${section.replace(' ', '-')}_!END`)
      ]
    }
    ];}));

  return itemSubsection({
    subsectionTitle: subsectionTitles.marketingCosts,
    subsectionContent: sectionItems,
    isVariation: false,
    unbreakable: false
  });
}

